import React, {useState} from 'react';
import "./index.scss"
import {useRequest} from "ahooks";
import {getSurveysBySurveyGroupId, isTokenValid} from "../../../requests";
import {useParams} from "react-router-dom";
import {Button, Spin, Table, TableProps, Tooltip} from "antd";
import {STEPPER_STEPS, SURVEY_STATUS_ENUM, SURVEY_TYPE_MAP} from "../../../constants";
import Stepper from "../Stepper";
import {ReactComponent as OverallReportButton} from '../../../assets/overall-report-icon.svg';
import {ReactComponent as OverallReportButtonHovered} from '../../../assets/overall-report-icon-hover.svg';

const AdminSurveyList = () => {
    const {data: tokenValidation} = useRequest(isTokenValid)

    interface DataType {
        key: number;
        index: number;
        name: string;
        status: string;
    }

    interface SurveySimpleInfo {
        id: string,
        survey_type: number,
        user_type: string,
        status: string
    }

    const generateActiveStep = (status: string) => {
        switch (status) {
            case 'TO_DO':
                return 0
            case 'IN_PROGRESS':
                return 1
            case 'SUBMITTED':
                return 2
            case 'REPORT_FINISHED':
                return 4
            default:
                return 0
        }
    }


    let {uuid} = useParams();
    // @ts-ignore
    const {data: surveys, loading: dataLoading} = useRequest(() => getSurveysBySurveyGroupId(uuid), {pollingInterval: 30000})
    const items = surveys?.map(((u: SurveySimpleInfo, i: number) => {
        return {
            key: u.id,
            index: i + 1,
            name: SURVEY_TYPE_MAP[u.survey_type as keyof typeof SURVEY_TYPE_MAP].name,
            status: u.status,
        }
    }))

    const columns: TableProps<DataType>['columns'] = [
        {
            title: '问卷',
            dataIndex: 'index',
            key: 'index',
            render: (index) => <div className={'index-text'}>No.{index}</div>,
        },
        {
            title: '测评',
            dataIndex: 'name',
            key: 'name',
            width: 500,
            render: (name) => <div className={'name-text'}>{name}</div>,
        },
        {
            title: '',
            dataIndex: 'key',
            key: 'into-report',
            width: 500,
            render: (key, obj) => <div className={'buttons'}><Button size={'large'} onClick={() => {
                window.open(`/survey?id=${key}&sgid=${uuid}&rd=true`, '_blank');
            }}>进入问卷</Button><Tooltip title={obj.status !== SURVEY_STATUS_ENUM[3] ? '报告还未生成' : ''}><Button
                size={'large'} disabled={obj.status !== SURVEY_STATUS_ENUM[3]}>进入报告</Button></Tooltip></div>,
        },
        {
            title: '分析进度',
            dataIndex: 'status',
            key: 'status',
            render: (status, obj) => {
                return (<div>
                    <Stepper steps={STEPPER_STEPS} activeStep={generateActiveStep(status)}/>
                </div>)
            }
        }
    ]

    const [overallReportButtonHovered, setOverallReportButtonHovered] = useState(false);

    return <>
        {dataLoading && <Spin fullscreen size={"large"}/>}
        {!dataLoading && <>
            <div className={'overall-report-button'}
                 onClick={() => {
                     window.open(`/admin-allreport/${uuid}`, '_blank')
                 }}
                 onMouseEnter={() => setOverallReportButtonHovered(true)}
                 onMouseLeave={() => setOverallReportButtonHovered(false)}>
                {overallReportButtonHovered ?
                    <OverallReportButtonHovered/> : <OverallReportButton/>}
            </div>
            <div className={"out-container"}>
                <div className={'list-container admin-list-container'}>
                    <Table columns={columns}
                           dataSource={items}
                           pagination={false}
                           className={"survey-list__table"}
                           rowClassName={"survey-list__table-row"}
                           onRow={(record) => {
                               return {
                                   onClick: () => {
                                       if (record.status === SURVEY_STATUS_ENUM[3]) {
                                           window.open(`/admin-report/${record.key}`, '_blank');
                                       }
                                   },
                               };
                           }}/>
                </div>
            </div>
        </>}
    </>

}

export default AdminSurveyList;