import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from "./components/Header";
import SurveyContainer from "./components/SurveyContainer";
import NotFound from "./components/NotFound";
import Survey from "./components/Survey";
import Admin from "./components/Admin";
import AdminSurveyList from "./components/Admin/AdminSurveyList";
import AdminSurveyReport from "./components/Admin/AdminSurveyReport";
import AdminAllReport from "./components/Admin/AdminAllReport";
import ReportPdf from "./components/Admin/AdminAllReport/ReportPdf";
import Login from "./components/Login";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <div
            className={`element-container ${window.location.pathname === '/survey' ? 'element-container-survey' : ''}`}>
            <Header/>
            <Router>
                <Routes>
                    <Route path="/sg" element={<SurveyContainer/>}/>
                    <Route path="/survey" element={<Survey/>}/>
                    <Route path="/admin" element={<Admin/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/admin-survey/:uuid" element={<AdminSurveyList/>}/>
                    <Route path="/admin-report/:uuid" element={<AdminSurveyReport/>}/>
                    <Route path="/admin-allreport/:uuid" element={<AdminAllReport/>}/>
                    <Route path="/report-pdf/:uuid" element={<ReportPdf/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Router>
        </div>
    </React.StrictMode>
);

